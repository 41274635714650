.linkhub-layout {
  height: 100vh;
  margin: auto;
}

.linkhub-background {
  z-index: -1;
  height: 100vh;
  margin: auto;
  width: 100vw;
  position: fixed;
  background-image: linear-gradient(135deg, #0972cd, #00e476);
}

.linkhub-name {
  margin-top: 2vh;
  margin-bottom: 2vh;
  color: white;
  font-size: max(4vh, 45px);
  text-align: center;
}

.pfp-img-container {
  display: flex;
  justify-content: center;
  padding-top: 2.5vh;
}

.img-profile {
  z-index: 1;
  border-radius: 50%;
  width: max(300px, 15vh);
  margin: auto;
}

.links-container {
  z-index: 1;
  margin-top: 4vh;
  margin: auto;
  padding-bottom: 4vh;
  width: 70%;
  border-radius: 15px;
  border: 2px solid rgba(255, 255, 255, 1);
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(3px);
}

.link-button-container {
  width: 100%;
  margin: auto;
}

.bg-circles {
  z-index: -1;
}

@media only screen and (min-width: 1000px) {
  .linkhub-name {
    margin-top: 2vh;
    margin-bottom: 2vh;
    color: white;
    font-size: max(2vw, 45px);
    text-align: center;
  }

  .img-profile {
    border-radius: 50%;
    width: max(100px, 5vw);
    margin: auto;
  }

  .links-container {
    z-index: 1;
    margin-top: 4vh;
    margin: auto;
    padding-bottom: 4vh;
    width: 40%;
    border-radius: 15px;
    border: 2px solid rgba(255, 255, 255, 1);
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(3px);
  }
}
/* cat was here =^.^= */
