.social-bar-layout {
    height: 2.5vh;
    padding-top: 4vh;
    margin: auto;
    text-align: center;
}

@media only screen and (min-width: 1000px) {
    .social-bar-layout {
        height: 4vh;
        padding-top: 4vh;
        margin: auto;
        text-align: center;
    }
}